.modal-card {
  padding: 45px 1rem 1rem;

  border-radius: 35px 35px 0 0;
  background-color: var(--bg-dark);
  position: absolute;
  z-index: 100;
  bottom: -1000px;
  left: 0;
  width: 100%;
  border-top: 3px solid var(--bg-yellow);
  transition: bottom ease-in-out 0.5s;
}

.modal-card.visible-modal-card {
  bottom: 0;
}

.modal-card .btn-close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  /* background: #fff; */
  color: rgba(255, 255, 255, 0.3);
}

.modal-card::before {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: green;
  border-radius: 35px 35px 0 0;
}
