.task-item {
  width: 100%;
  border-bottom: 1px solid var(--bg-dark-gray);
  padding: 0.5rem;
}

.task-item .task-title {
  color: #fff;
  font-size: 0.8rem;
}

.task-item .task-icon {
  background: var(--bg-yellow);
  color: var(--bg-dark);
  min-width: 40px;
  min-height: 40px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-item .task-reward {
  color: var(--bg-yellow-light);
  font-weight: bold;
}

.task-item .task-button {
  border-radius: 25px;
  text-align: center;
  padding: 5px 18px;
  min-width: 70px;
  font-size: 0.75rem;
  font-weight: bold;
}

.task-item .task-button.task-Incomplete {
  background: var(--bg-dark-gray);
  color: var(--bg-dark);
}

.task-item .task-button.task-Completed {
  background: var(--bg-yellow);
  color: var(--bg-dark);
}

.task-item .task-button.task-Claimed {
  background: var(--bg-blue);
  color: #fff;
}

.task-item .task-button.task-Checking {
  background: var(--bg-dark-green);
  color: #fff;
}

.task-modal-card .details-widget {
  padding: 0.5rem;
  background: var(--bg-dark-light);
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 0.5rem;
}

.task-modal-card .details-widget:last-child {
  margin-right: 0;
}

.task-modal-card .details-widget .details-widget-value,
.task-modal-card .details-widget .details-widget-title {
  font-size: 1rem;
  padding: 0.1rem 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.2);
}

.task-modal-card .details-widget .details-widget-value {
  font-size: 0.9rem;
  color: var(--bg-dark-green);
}
