:root {
  --bg-dark: #1c1c28;
  --bg-dark-light: #282834;
  --bg-yellow: #f7e05b;
  --bg-red: rgb(192, 57, 43);
  --bg-blue: #01a0e2;
  --bg-dark-green: #3e8e41;
  --bg-yellow-light: rgb(253, 209, 66, 0.4);
  --bg-green: #6abf69;
  --bg-muted-orange: #f78c5b;
  --bg-dark-gray: #6c707b;
  --bg-light-gray: #bcbcbd;
}
