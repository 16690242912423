.notification-container {
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  align-items: baseline;
  z-index: 1000;
  color: #fff;
  margin-bottom: 1rem;
  width: 100%;
}

.notification-icon-container {
  margin-right: 10px;
}

.notification-icon {
  color: #fff;
}

.notification-text {
  font-size: 14px;
}

.notification-Error {
  background-color: var(--bg-red);
}

.notification-Success {
  background-color: var(--bg-green);
}

.notification-Info {
  background-color: var(--bg-blue);
}
