.referral-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
}

.referral-item-pic,
.referral-item-pic img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.referral-item-pic {
  background-color: var(--bg-green);
}

.referral-item-pic img {
  position: relative;
}

.referral-item-name {
  padding-left: 10px;
  font-size: 0.8rem;
  font-style: italic;
}
