.farming-button {
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 10px;
  color: #000;
  position: relative;
  overflow: hidden;
}

.farming-button > div {
  z-index: 2;
}

#farming-button-progreebar {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: var(--bg-yellow-light);
  border-radius: 25px;
  transition: ease-in 0.3s all;
}

#farming-button-icon {
  font-size: 24px;
}

.farming-button.ReadyToClaim {
  background-color: var(--bg-dark-green);
  color: #fff;
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.farming-button.ReadyToFarm {
  background-color: var(--bg-blue);
  color: #fff;
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.farming-button.Farming {
  animation: bounce; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}
