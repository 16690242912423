.text-action-button-container {
  background-color: var(--bg-green);
  border-radius: 25px;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.text-action-button-text {
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.text-action-button-button {
  border-radius: 25px;
  background-color: var(--bg-dark);
  font-size: 13px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  color: white;
}

.text-action-button-button .clickable {
  cursor: pointer;
}

.circle-rounded {
  border-radius: 25px;
}
