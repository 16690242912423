@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "./theme.css";
@import "animate.css";

h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
a,
button,
span,
div {
  font-family: "Roboto", sans-serif;
}

button:disabled {
  background-color: #ccc !important;
  cursor: unset;
}

* {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.clickable {
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-icon {
  animation: rotate 1s linear infinite;
}
