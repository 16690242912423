.profile-pic,
.profile-pic img {
  border-radius: 50%;
  width: 66px;
  height: 66px;
}

.profile-pic {
  background-color: var(--bg-green);
}

.profile-pic img {
  position: relative;
  top: 15px;
}

.profile-name {
  padding-left: 10px;
}

.upgrade-button {
  background-color: var(--bg-green);
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
