.tasks-screen .nav-tabs {
  background-color: var(--bg-dark);
  border-radius: 15px;
  border: none;
  overflow: hidden;
}

.tasks-screen .nav-tabs .nav-link {
  border-radius: 15px !important;
  color: #fff;
  border: none;
}

.tasks-screen .nav-tabs .nav-link.active {
  background: var(--bg-yellow);
  color: var(--bg-dark);
}
